import request from '@/utils/request'


// 查询余额变动列表
export function listDetails(query) {
  return request({
    url: '/balance/details/list',
    method: 'get',
    params: query
  })
}

// 查询余额变动分页
export function pageDetails(query) {
  return request({
    url: '/balance/details/page',
    method: 'get',
    params: query
  })
}

// 查询余额变动详细
export function getDetails(data) {
  return request({
    url: '/balance/details/detail',
    method: 'get',
    params: data
  })
}

// 新增余额变动
export function addDetails(data) {
  return request({
    url: '/balance/details/add',
    method: 'post',
    data: data
  })
}

// 修改余额变动
export function updateDetails(data) {
  return request({
    url: '/balance/details/edit',
    method: 'post',
    data: data
  })
}

// 删除余额变动
export function delDetails(data) {
  return request({
    url: '/balance/details/delete',
    method: 'post',
    data: data
  })
}
