<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="变动类型,0-充值,1-提现,2-支付悬赏,3-接收悬赏" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="变动金额" prop="money" >
        <a-input v-model="form.money" placeholder="请输入变动金额" />
      </a-form-model-item>
      <a-form-model-item label="提现状态,0-审核中,1-已到账,2-审核失败" prop="status" >
      </a-form-model-item>
      <a-form-model-item label="操作前金额" prop="beforeMoney" >
        <a-input v-model="form.beforeMoney" placeholder="请输入操作前金额" />
      </a-form-model-item>
      <a-form-model-item label="操作后金额" prop="afterMoney" >
        <a-input v-model="form.afterMoney" placeholder="请输入操作后金额" />
      </a-form-model-item>
      <a-form-model-item label="用户id,外键{zb_user.id}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id,外键{zb_user.id}" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDetails, addDetails, updateDetails } from '@/api/balance/details'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        type: null,

        money: null,

        status: 0,

        beforeMoney: null,

        afterMoney: null,

        userId: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '变动类型,0-充值,1-提现,2-支付悬赏,3-接收悬赏不能为空', trigger: 'change' }
        ],
        money: [
          { required: true, message: '变动金额不能为空', trigger: 'blur' }
        ],
        beforeMoney: [
          { required: true, message: '操作前金额不能为空', trigger: 'blur' }
        ],
        afterMoney: [
          { required: true, message: '操作后金额不能为空', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '用户id,外键{zb_user.id}不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        money: null,
        status: 0,
        beforeMoney: null,
        afterMoney: null,
        userId: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDetails({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDetails(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDetails(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
